import Blog from "../../components/Blog/Blog"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import './BlogPage.scss'


const BlogPage = ({name, img, description, change1, change2, change3, english, uzbek, russian}) => {
    return(
        <div className="blogpage">
            {/* <Navbar /> */}
            <Blog img={img} name={name} english={english} russian={russian} uzbek={uzbek} description={description} change1={change1} change2={change2} change3={change3}  />
            {/* <Footer /> */}
        </div>
    )
}
export default BlogPage