import React, { useState } from "react";
import './Navbar.scss'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import ellipse1 from '../../Assets/img/Ellipse 84.jpg'
import ellipse2 from '../../Assets/img/Ellipse 85.jpg'
import ellipse3 from '../../Assets/img/Ellipse 86.jpg'
import ellipse4 from '../../Assets/img/Ellipse 87.jpg'
import ellipse5 from '../../Assets/img/Ellipse 88.jpg'
import axios from "axios";
import { useEffect } from "react";
import Stories from 'react-insta-stories';
import Slider from "react-slick";

const Navbar = () => {

  const [data, setData] = useState([])
  const urlData = 'https://api.honor-furniture.uz/profiles/'

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlData);
      setData(request.data);
      return request;
    };
    fetchData()
  }, [urlData]);

  const [story, setStory] = useState([])
  const urlStory = 'https://api.honor-furniture.uz/story'

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlStory);
      setStory(request.data?.stories);
      return request;
    };
    fetchData()
  }, [urlStory]);

  const [videoIndex, setvideoIndex] = useState(0);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(item) {
    setIsOpen(true);
    setvideoIndex(item)
  }

  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function changeIndex() {
    if (videoIndex < 4) {
      setvideoIndex(videoIndex + 1)
    } else if (videoIndex == 4) {
      setvideoIndex(0)
    }
  }
  function changeIndex1() {
    if (videoIndex > 0) {
      setvideoIndex(videoIndex - 1)
    } else if (videoIndex == 0) {
      setvideoIndex(4)
    }
  }

  const stories =
    [
      {
        url: story.map((c) => c.media)[videoIndex],
        duration: 2000,
        type: 'image'
      },
    ]

  const settings = {
    dots: true,
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  };


  return (
    <div className="navbar__splide" id="story">
      <Slider {...settings} className="carousel">
        <div onClick={() => openModal(0)} className=" slide-btn">
          <img src={ellipse1} className="slide-img" alt="Image 1" />
        </div>
        <div onClick={() => openModal(1)} className=" slide-btn">
          <img src={ellipse2} className="slide-img" alt="Image 2" />
        </div>
        <div onClick={() => openModal(2)} className=" slide-btn">
          <img src={ellipse3} className="slide-img" alt="Image 2" />
        </div>
        <div onClick={() => openModal(3)} className=" slide-btn">
          <img src={ellipse4} className="slide-img" alt="Image 2" />
        </div>
        <div onClick={() => openModal(4)} className=" slide-btn">
          <img src={ellipse5} className="slide-img" alt="Image 2" />
        </div>
        <div onClick={() => openModal(1)} className=" slide-btn">
          <img src={ellipse3} className="slide-img" alt="Image 2" />
        </div>
      </Slider>
      {/* <div className="container">
          <Splide data-splide='{"type":"loop"}'  className='navbar__lists'   aria-label="React Splide Example">
            <SplideSlide   className='navbar__slides border'>
                <button onClick={() => openModal(0)}  className=" navbar__button">
                    <img src={ellipse1} className="navbar__pic" alt="Image 1"/>
                </button>
                <button onClick={() => openModal(1)}  className=" navbar__button">
                    <img src={ellipse2} className="navbar__pic" alt="Image 2"/>
                </button>
                <button onClick={() => openModal(2)}  className=" navbar__button">
                    <img src={ellipse3} className="navbar__pic" alt="Image 2"/>
                </button>
                <button onClick={() => openModal(3)}  className=" navbar__button">
                    <img src={ellipse4} className="navbar__pic" alt="Image 2"/>
                </button>
                <button onClick={() => openModal(4)}  className=" navbar__button">
                    <img src={ellipse5} className="navbar__pic" alt="Image 2"/>
                </button>
            </SplideSlide>
          </Splide>
        </div>    */}
      {modalIsOpen &&
        <div className={`instagram ${!modalIsOpen && "d-none"}`}>
          <Stories className="story" stories={stories} />
          <div className="close-icon" onClick={() => closeModal()}>&times;</div>
          <div className="right-icon" onClick={() => changeIndex()}>&#62;</div>
          <div className="left-icon" onClick={() => changeIndex1()}>&#60;</div>
          <div className="contr-story" onClick={() => closeModal()}></div>
        </div>
      }
    </div>
  )
}
export default Navbar