import './About.scss'
import hotel from '../../Assets/img/hotel.png'
import { useTranslation } from 'react-i18next'

const About = ({uzbek, russian, english}) => {

    const {t} = useTranslation()

    return(
        <div id="about" className="about">
            <div className="container">
                {
                    english &&
                    <h2 className="about__name">About us</h2>
                }
                {
                    russian &&
                    <h2 className="about__name">О нас</h2>
                }
                {
                    uzbek &&
                    <h2 className='about__name'>O'zimiz haqimizda</h2>
                }            
                <div className="about__page"><img src={hotel} alt="" className="about__img" /></div>
                <p className="about__text">{t("abouttxt")}</p>                                                
            </div>
        </div>
    )
}
export default About