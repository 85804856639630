import "./Country.css";
import CountryFlag from "../../Assets/img/flag.png"
import CountryFlag1 from "../../Assets/img/flag1.png"
import CountryFlag2 from "../../Assets/img/flag2.png"
import { useTranslation } from "react-i18next";

const  Country=()=>{

    const {t} = useTranslation()

    return(
        <section id="export" className="flags">
            <div className="wrapper">
                <h2 className="title">
                    {t("export")}
                </h2>
                {/* <div className="country-header">
                </div> */}
                <div className="img col-md-12">
                    <img src={CountryFlag} alt="" className="flag-img" /> 
                    <img src={CountryFlag1} alt="" className="flag-img" />          
                    <img src={CountryFlag2} alt="" className="flag-img" /> 
                    {/* <ul className="country-list">
                        <li className="country-item">
                        </li>
                        <li className="country-item">
                            <img src={CountryFlag1} alt="" className="country-img" />          
                        </li> 
                        <li className="country-item">
                            <img src={CountryFlag2} alt="" className="country-img" />          
                        </li>
                    </ul> */}
                 </div>
            </div>
        </section>
    )
}

export default Country;