import Catalog from '../../components/Catalog/Catalog'
import Footer from '../../components/Footer/Footer'
import './Catagory3.scss'


const Catagory3 = ({chageIndex}) => {
    return(
        <div className="catagory3">
            <Catalog chageIndex={chageIndex} />
            <Footer />
        </div>
    )
}
export default Catagory3