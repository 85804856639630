import React, { useEffect, useState } from 'react';
import './Scss/Home.scss';
import Img1 from './images/slider.png'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Home = ({english, russian, uzbek, chageIndex}) => {

    const dataSlider = [
        {id: 1, image: Img1, name_uz: "Stol va stullar", description_uz: "Qulay uslubdagi yumshoq stullar bilan uyingizda shinam muhit yarating. Dizayn tanlash uchun katalog qismiga o'ting"},
        {id: 1, image: Img1, name_uz: "Stol va stullar", description_uz: "Qulay uslubdagi yumshoq stullar bilan uyingizda shinam muhit yarating. Dizayn tanlash uchun katalog qismiga o'ting"},
        {id: 1, image: Img1, name_uz: "Stol va stullar", description_uz: "Qulay uslubdagi yumshoq stullar bilan uyingizda shinam muhit yarating. Dizayn tanlash uchun katalog qismiga o'ting"},
        {id: 1, image: Img1, name_uz: "Stol va stullar", description_uz: "Qulay uslubdagi yumshoq stullar bilan uyingizda shinam muhit yarating. Dizayn tanlash uchun katalog qismiga o'ting"}
    ]

    const [dataProducts, setDataProducts] = useState([]);
    const urlProducts = `https://api.honor-furniture.uz/categories/`;
  
    useEffect(() => {
      async function fetchData() {
        const request = await axios.get(urlProducts);
        setDataProducts(request.data);
        return request;
      };
      fetchData()
    }, [urlProducts]);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        cssEase: "linear"
    };

    const {t} = useTranslation()

    return (
        <div className="Home">
            <Slider {...settings} className="carousel">
                {dataProducts.map((data) => (
                    <div className="page-slide">
                        <img src={data.image} alt="img" />
                        <div className="text">
                            <h1 className="name">{english && data.name_en} {uzbek && data.name_uz} {russian && data.name_ru}</h1>
                            {/* <p className='desc'>{english && data.description_en.slice(0,160)}{uzbek && data.description_uz.slice(0,160)}{russian && data.description_ru.slice(0,160)}...</p> */}
                            <div className="link">
                                <Link to='/products' className='explore' onClick={() => chageIndex(data.id)}>{t("readmore")}</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Home;