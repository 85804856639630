import Catalogy from '../../components/Catalogy/Catalogy'
import Footer from '../../components/Footer/Footer'
import './Catagory1.scss'


const Catagory1 = ({chageIndex}) => {
    return(
        <div className="catagory1">
            {/* <Navbar /> */}
            <Catalogy chageIndex={chageIndex} />
            <Footer />
        </div>
    )
}
export default Catagory1