import './Catalogy.scss'
import arrow from '../../Assets/img/arrow.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Catalogy = ({chageIndex}) => {


    
    function loadWindow(item){
        window.location.reload(false)
    }

    const {t} = useTranslation()

    return(
        <div className="catalogy">
            <div className="container">
                 <h2 className="catalogy__name">{t("bestsale")}</h2>
                 <div className="catalogy__title">
                 <Link onClick={() => chageIndex(2)}  to="/products" className="catalogy__link">{t("catalogue")}   <img src={arrow} alt="" className="catalogy__img" /></Link>
                 </div>

                 <ul className="catalogy__list">
                    <li className="catalogy__item">
                        {/* <a href='#' className="catalogy__number">01-</a> */}
                        <Link to="/catagory1" className='catalogy__number'>01-</Link>
                    </li>
                    <li className="catalogy__item">
                        {/* <a  href='#' className="catalogy__numbers">02-</a> */}
                        <Link  to="/catagory2" className='catalogy__numbers'>02-</Link>
                    </li>
                    <li className="catalogy__item">
                        {/* <a  href='#' className="catalogy__numbers">03-</a> */}
                        <Link  to='/catagory3' className="catalogy__numbers">03-</Link>
                    </li>
                 </ul>
            </div>
        </div>
    )
}
export default Catalogy