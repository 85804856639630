import React from "react";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";
import Navbar from "../../components/Navbar/Navbar";
import './HeaderInput.css'

const HeaderInput = ({ dataBlog, modal, setModal, showModal, closeModal, modalData, chageIndex, inputValue, dataProd, filterData, change1, change2, change3, uzbek, english, russian}) =>{
    return(
        <div className="headerinput">
            <Navbar />
            <Home uzbek={uzbek} english={english} russian={russian} chageIndex={chageIndex} />
            {/* <Header dataBlog={dataBlog} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} chageIndex={chageIndex} dataProd={dataProd} inputValue={inputValue} filterData={filterData} change1={change1} change2={change2} change3={change3} uzbek={uzbek}  
            english={english} russian={russian}
            /> */}
        </div>
    )
}
export default HeaderInput