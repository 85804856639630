import './Client.scss'
import person from '../../Assets/img/person.png'
import golden from '../../Assets/img/golden.png'
import nrg from '../../Assets/img/nrg.png'
import korzinka from '../../Assets/img/korzinka.png'
import makro from '../../Assets/img/makro.png'
import murad from '../../Assets/img/murad.png'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import axios from 'axios';


const Client = ({uzbek, russian, english}) => {

    const {t} = useTranslation()

    const [dataMenu, setDataMenu] = useState([]);
    const urlMenu = `https://api.honor-furniture.uz/rates/`;
  
    useEffect(() => {
      async function fetchData() {
        const request = await axios.get(urlMenu);
        setDataMenu(request.data);
        return request;
      };
      fetchData()
    }, [urlMenu]);

    const [dataPartners, setDataPartners] = useState([]);
    const urlPartners = `https://api.honor-furniture.uz/partners/`;
  
    useEffect(() => {
      async function fetchData() {
        const request = await axios.get(urlPartners);
        setDataPartners(request.data);
        return request;
      };
      fetchData()
    }, [urlPartners]);


    // const { t } = useTranslation()


    return(
        <div id='person' className="client">
            <div className="wrapper" id='comment'>
                <h2 className="title">{t("client")}</h2>
                <div className="client-page row">
                    {dataMenu.slice(0,5).map((data) => (
                        <div className="col-3 person">
                            <div className="body">
                                <img src={data.image} alt="img" className='person-img' />
                                <h6 className='name'>{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h6>
                                <div className="stars">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </div>
                                <p className='desc'>{english && data.description_en} {russian && data.description_ru} {uzbek && data.description_uz}</p>
                            </div>
                        </div>
                    ))}
                </div>                
            </div>

            <div id="partners" className="client__box">
                <div className="container1">
                    <h2 className="client__name">{t("partners")}</h2>
                    <div className="client__title ">
                        {dataPartners.map((data) => (
                             <div className="col-2">
                                <a href="#" className="client__link"><img src={data.image} alt="" className="client__pic" /></a>
                            </div>
                        ))}
                        {/* <div className="col-2">
                            <a href="#" className="client__link"><img src={golden} alt="" className="client__pic" /></a>
                        </div>
                        <div className="col-2">
                            <a href="#" className="client__link"><img src={nrg} alt="" className="client__picture" /></a>
                        </div>
                        <div className="col-2">
                           <a href="#" className="client__link"> <img src={korzinka} alt="" className="client__pics" /></a>
                        </div>
                        <div className="col-2">
                            <a href="#" className="client__link"><img src={makro} alt="" className="client__pics" /></a>
                        </div>
                        <div className="col-2">
                            <a href="#" className="client__link"><img src={murad} alt="" className="client__pictures" /></a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Client