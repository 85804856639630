import './Footer.css'
import AbbaCoding from "../../Assets/img/1.png";
import AbbaMedia from "../../Assets/img/2.png";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Scss/Footer.scss';
import { useState } from 'react';

const Footer = ({english, russian, uzbek})=>{

    const {t} = useTranslation()

    const [email, setEmail] = useState("");
    const [redEmail, setRedEmail] = useState(false);
    const [invalid, setInvalid] = useState(false);

    function handleChange(item){
        setEmail(item)
        setRedEmail(false)
    }

    const [modalOk, setModalOk] = useState(true)

    function changeModal(){
        setModalOk(false)
    }

    let bot = {
        TOKEN: "5568615804:AAHPwxSUQSH2POp4xOAdWgcuqZrul87tNJ8",
        chatID: "-1001554937927",
        message: `Email: ${email}`
    }

    function sendMessage(e){          
        if(email === ""){           
            setRedEmail(true)        
        }else if(!email.includes("@")){           
            setRedEmail(true)    
            setInvalid(true)        
        }else if(!email.includes(".")){           
            setRedEmail(true) 
            setInvalid(true)               
        }else{
            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method : "GET"
            })
            .then(success => {
                console.log('send message')
            }, error => {
                console.log(error)
            })

            setEmail("")
            changeModal()
        }
        
    }

    return(
        <div className="Footer">
            <div className='wrapper'>
                <div className='row f1'>
                    <div className='col-md-4 p-2'>  
                        <p className="title">
                            {t("blostext")} 
                        </p>  
                        <div className="col-12 position-relative">
                            <input value={email} id="eml" onChange={(e) => handleChange(e.target.value)} className={`form-control mt-3 ${redEmail && "red-line"}`} type="email" placeholder={`${t("email")}`} required />
                            <label htmlFor="eml" className={`form-label mt-3 ${redEmail && "red-label"}`}>{invalid ? t("invalid") : t("enter")}</label>
                            <div className="col-12 btns">
                                <button onClick={() => sendMessage()} className='btn'>{t("sub")}</button>                      
                            </div>
                        </div> 
                    </div>
                    <div className='col-md-8 texts'>
                        <div className='col-md-4 com'>
                            <ul className="">
                                <li className="title"><h6>{t("f1")}:</h6></li>
                                <li className=''><a href={`/#story`} >{t("f11")}</a></li>
                                <li className=''><a href='/#partners'>{t("f12")}</a></li>
                                <li className=''><a href='/#comment'>{t("f13")}</a></li>                                
                                <li className=''><a href='/#export'>{t("f14")}</a></li>
                            </ul>
                        </div>
                        <div className='col-md-4 prod'>
                            <ul className="">
                                <li className="title"><h6>{t("f2")}</h6></li>
                                <li className=''><a href='/#about'>{t("f21")}</a></li>
                                <li className=''><Link to='/blog1'>{t("f22")}</Link></li>
                                <li className=''><Link to='/blog2'>{t("f23")}</Link></li>
                                <li className=''><a href='/#best'>{t("f24")}</a></li>                                
                            </ul>
                        </div>
                        <div className='col-md-4'>
                            <ul className="">
                                <li className="title"><h6>{t("f3")}</h6></li>
                                <li className=''><a href='tel:+99898-157-00-89'>+99898 157 00 89</a></li>
                                <li className=''><a href='tel:+99895-170-00-89'>+99895 170 00 89</a></li>
                                <li className=''><a href='mailto: info@honorfurniture.uz'>info@honorfurniture.uz</a></li>                                
                                <li className=''><a href='#'>{t("adress")}</a></li>
                                <li className=''><a href='https://www.instagram.com/honorfurniture_kokcha/'>{t("instagram")}</a></li>
                            </ul>
                        </div>                    
                    </div>
                </div>

                <div className='f2'>
                    <div className='col-6 com'>
                        <ul className="">
                            <li className="title"><h6>{t("f1")}:</h6></li>
                            <li className=''><a href={`/#story`} >{t("f11")}</a></li>
                            <li className=''><a href='/#partners'>{t("f12")}</a></li>
                            <li className=''><a href='/#comment'>{t("f13")}</a></li>                                
                            <li className=''><a href='/#export'>{t("f14")}</a></li>                            
                        </ul>
                    </div>
                    <div className='col-6'>
                        <ul className="">
                            <li className="title"><h6>{t("f2")}</h6></li>
                            <li className=''><a href='/#about'>{t("f21")}</a></li>
                            <li className=''><Link to='/blog1'>{t("f22")}</Link></li>
                            <li className=''><Link to='/blog2'>{t("f23")}</Link></li>
                            <li className=''><a href='/#best'>{t("f24")}</a></li>  
                        </ul>
                    </div>
                    <div className='col-6'>
                        <ul>
                            <li className="footer-text1">
                                <p className="title">
                                    {t("blostext")} 
                                </p>   
                                <div className="col-12 position-relative">
                                    <input value={email} id="eml" onChange={(e) => handleChange(e.target.value)} className={`form-control mt-3 ${redEmail && "red-line"}`} type="email" placeholder={`${t("email")}`} required />
                                    <label htmlFor="eml" className={`form-label mt-3 ${redEmail && "red-label"}`}>{invalid ? t("invalid") : t("enter")}</label>
                                    <div className="col-12 btns">
                                        <button onClick={() => sendMessage()} className='btn'>{t("sub")}</button>                      
                                    </div>
                                </div> 
                            </li>         
                        </ul>
                    </div>
                    <div className='col-6 prod'>
                        <ul className="">
                            <li className="title"><h6>{t("f3")}</h6></li>
                            <li className=''><a href='tel:+99898-157-00-89'>+99898 157 00 89</a></li>
                            <li className=''><a href='tel:+99895-170-00-89'>+99895 170 00 89</a></li>
                            <li className=''><a href='mailto: info@honorfurniture.uz'>info@honorfurniture.uz</a></li>                                
                            <li className=''><a href='#'>{t("adress")}</a></li>
                            <li className=''><a href='https://www.instagram.com/honorfurniture_kokcha/'>{t("instagram")}</a></li>
                        </ul>
                    </div>
                </div>
            </div>    
            <div className="footer-bottom">
                <div className="container">
                    <p className="bottom-text">{t("allrights")}</p>
                    <ul className="bottom-list">
                        <li className="bottom-item">
                        <p className="bottom-subtext">{t("created")}</p>
                        </li>
                        <li className="bottom-item">
                            <img src={AbbaCoding} alt="" className="bottom-img" />
                        </li>
                        <li className="bottom-item">
                            <img src={AbbaMedia} alt="" className="bottom-img" />
                        </li>
                    </ul>
                </div>
            </div>     
            <div className={`contrast ${modalOk && "d-none"}`} onClick={() => setModalOk(true)}></div>
            <div className={`col-md-6 offset-md-3 buy-ok ${!modalOk && "show-modal"}`}>
                <i className='fa fa-times' onClick={() => setModalOk(true)} ></i> 
                {english &&
                    <h1>THANK YOU FOR YOUR APPEAL &#128522;</h1> 
                } 
                {russian &&
                    <h1>СПАСИБО ЗА ОБРАЩЕНИЕ &#128522;</h1> 
                } 
                {uzbek &&
                    <h1>MUROJAATINGIZ UCHUN TASHAKKUR &#128522;</h1> 
                }                                                 
                <div className='col-md-4'>                    
                    {english && 
                        <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
                    }
                    {russian && 
                        <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
                    }
                    {uzbek && 
                        <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
                    }                       
                </div>
            </div>    
        </div>
    //    <footer id="contacts" className="footer">
    //     {/* <div className="footer-header">
    //         <div className="container">
    //             <div className="footer-top">
    //                 <div className="col-3">
    //                     <input type="email" placeholder={`${t("email")}`} />
    //                     <button className='btn'>{t("sub")}</button>
    //                 </div>
    //                 <div className="col-3">
    //                     <p className="footer-text">
    //                         {t("blostext")} 
    //                     </p>
    //                 </div>
    //                <div className="footer-right">
                        
    //                    <ul className="footer-list">
    //                        <li className="footer-item">
    //                            <h3 className="footer-title">
    //                                {t("f1")}
    //                            </h3>
    //                        </li>
    //                        <li className="footer-item">
    //                            <a href="/#story" className="footer-link">
    //                            {t("f11")}
    //                            </a>
    //                        </li>
    //                        <li className="footer-item">
    //                            <a href="#partners" className="footer-link">
    //                               {t("f12")}
    //                            </a>
    //                        </li>        
    //                        <li className="footer-item">
    //                            <a href="/#comment" className="footer-link">
    //                            {t("f13")}
    //                            </a>
    //                        </li>
    //                        <li className="footer-item">
    //                            <a href="/#export" className="footer-link">
    //                            {t("f14")}
    //                            </a>
    //                        </li>
    //                    </ul>
    //                    <ul className="footer-list">
    //                        <li className="footer-item">
    //                            <h3 className="footer-title">
    //                              {t("f2")}
    //                            </h3>
    //                        </li>
    //                        <li className="footer-item">
    //                            <Link to="/about" className="footer-link">
    //                               {t("f21")}
    //                            </Link>
    //                        </li>
    //                        <li className="footer-item">
    //                            <Link to="/blog1" className="footer-link">
    //                                {t("f22")}
    //                            </Link>
    //                        </li>    
    //                        <li className="footer-item">
    //                            <Link to="/blog2" className="footer-link">
    //                               {t("f23")}
    //                            </Link>
    //                        </li>                               
    //                        <li className="footer-item">
    //                            <a href="/#best" className="footer-link">
    //                               {t("f24")}
    //                            </a>
    //                        </li>    
    //                    </ul>
    //                    <ul className="footer-list">
    //                        <li className="footer-item">
    //                            <h3 className="footer-title">
    //                                {t("f3")}
    //                            </h3>
    //                        </li>
    //                        <li className="footer-item">
    //                            <a href="tel:+99898-157-00-89" className="footer-link">
    //                            +99898 157 00 89
    //                            </a>
    //                            <a href="tel:+99895-170-00-89" className="footer-link">
    //                            +99895 170 00 89
    //                            </a>
    //                        </li>
    //                         <li className="footer-item">
    //                            <a href="mailto: info@honorfurniture.uz" className="footer-link">
    //                             info@honorfurniture.uz
    //                            </a>
    //                        </li>    
    //                        <li className="footer-item">
    //                            <a href="#" className="footer-link">{t("adress")}</a>
    //                        </li>
    //                        <li className="footer-item">
    //                            <a href="https://www.instagram.com/honorfurniture_kokcha/" className="footer-link">Instagram</a>
    //                        </li>  
    //                    </ul>
    //                    </div>
                       
    //                </div>
    //        </div>  
    //        </div>    */}


            
    //        <div className="footer-bottom">
    //            <div className="container">
    //                    <p className="bottom-text">
    //                    Все права защищены.
    //                    </p>
    //                    <ul className="bottom-list">
    //                        <li className="bottom-item">
    //                        <p className="bottom-subtext">
    //                        Разработано в
    //                        </p>
    //                        </li>
    //                        <li className="bottom-item">
    //                            <img src={AbbaCoding} alt="" className="bottom-img" />
    //                        </li>
    //                        <li className="bottom-item">
    //                            <img src={AbbaMedia} alt="" className="bottom-img" />
    //                        </li>
    //                    </ul>
    //                    </div>
    //        </div>
    //    </footer>
    )
}

export default Footer;