import { Gradient, Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import Products from './components/Products/Products';
import Category from './components/Category/Category';
import Country from './components/Country/Country';
import Footer from './components/Footer/Footer';
import SliderBox from './components/Slider/Slider';
import HeaderInput from './Page/HeaderInput/HeaderInput';
import Catagory1 from './Page/Catagory1/Catagory1';
import Catagory2 from './Page/Catagory2/Catagory2';
import Catagory3 from './Page/Catagory3/Catagory3';
import AboutPage from './Page/AboutPage/AboutPage';
import Client from './components/Client/Client';
import Sofa from './components/Sofa/Sofa';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import { useTranslation } from 'react-i18next';
import NavbarCopy from './components/Navbar copy/Navbar';
import BlogPage from './Page/BlogPage/BlogPage';
import Header from './components/Header/Header';
import  Gradients from './components/Gradient/Gradient'
import ScrollToTop from './components/Scrolls/ScrollToTop';

const App = () => {

  // data of products -------------------------------------------

  const [dataProducts, setDataProducts] = useState([]);
  const urlProducts = `https://api.honor-furniture.uz/products/`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlProducts);
      setDataProducts(request.data);
      return request;
    };
    fetchData()
  }, [urlProducts]);

  // data of categories -------------------------------------------

  const [dataMenu, setDataMenu] = useState([]);
  const urlMenu = `https://api.honor-furniture.uz/categories/`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlMenu);
      setDataMenu(request.data);
      return request;
    };
    fetchData()
  }, [urlMenu]);

  // data of subcategories -------------------------------------------

  const [dataTip, setDataTip] = useState([]);
  const urlTip = `https://api.honor-furniture.uz/subcategories/`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlTip);
      setDataTip(request.data);
      return request;
    };
    fetchData()
  }, [urlTip]);


  const [dataMenus, setDataMenus] = useState([]);
  const urlMenus = `https://api.honor-furniture.uz/rates/`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlMenus);
      setDataMenus(request.data);
      return request;
    };
    fetchData()
  }, [urlMenus]);

  // i18nexus

  const {t, i18n} = useTranslation()

  // Language components

  const [english, setEnglish] = useState(localStorage.getItem('i18nextLng') == "en" ? true : false);
  const [russian, setRussian] = useState(localStorage.getItem('i18nextLng') == "ru" ? true : false);
  const [uzbek, setUzbek] = useState(localStorage.getItem('i18nextLng') == "uz" ? true : false);   
  const [langTitle, setLangTitle] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : "") 

  function change1(item) {  
    i18n.changeLanguage("ru") 
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
    setRussian(item)
    setEnglish(!item)
    setUzbek(!item)
  }
  function change2(item) {
    i18n.changeLanguage("en") 
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
    setEnglish(item)
    setRussian(!item)
    setUzbek(!item)  
  }
  function change3(item) { 
    i18n.changeLanguage("uz") 
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
    setUzbek(item)
    setEnglish(!item)
    setRussian(!item)   
  }

  window.addEventListener("load", () => {    
    setRussian(true)
    setEnglish(false) 
    localStorage.setItem("i18nextLng", "en") 
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase()) 
  })

  // buy products ----------------------------------------------

  const [showBuy, setShowBuy] = useState(false)
  const [numberLikes, setNumberLikes] = useState(localStorage.getItem("like") ? localStorage.getItem("like").split(',') : 0)

  function showHandleBuy(item){
    setShowBuy(item)
  }

///Backend API

  // const [dataBlog, setData] = useState([])

  // useEffect( async () => {

  //     fetch(`http://93.189.40.27:2400/blogs/?format=json`)
  //     .then((res) => res.json())
  //     .then((dataBlog) => setData(dataBlog))
  // }, [])
  // console.log(dataBlog);

  //Blog API

  const [dataBlog, setDataBlog] = useState([]);
  const urlBlog = `https://api.honor-furniture.uz/blogs/`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlBlog);
      setDataBlog(request.data);
      return request;
    };
    fetchData()
  }, [urlBlog]);


  //Search qidiruvi 

  const [dataProd, setdata] = React.useState([])
  React.useEffect(() => {
    fetch('https://api.honor-furniture.uz/subcategories/')
      .then(response => response.json())
      .then(json => setdata(json))
  }, []);


  const [inputValue, setInputValue] = useState("")

    function filterData(e){
        setInputValue(e.target.value)
    }

    const search = (data) => {
      if(english){
        return data?.filter(post => post?.name_en?.toLowerCase().includes(inputValue.toLowerCase()))
      }else if(russian){
        return data?.filter(post => post?.name_ru?.toLowerCase().includes(inputValue.toLowerCase()))
      }else if(uzbek){
        return data?.filter(post => post?.name_uz?.toLowerCase().includes(inputValue.toLowerCase()))
      }
        // return data.filter(post => post.name_en.toLowerCase().includes(inputValue.toLowerCase()))
    }

     // counter for like and added cart

    const [countLike, setCountLike] = useState(localStorage.getItem("lk") ? localStorage.getItem("lk").split(',').filter((c) => c != "" && c).length : 0)
    
    function addCountLike(){
      setCountLike(countLike + 1)
    }
    function deletCountLike(){
      setCountLike(countLike - 1)
    }

    // category index

    const [catIndex, setcatIndex] = useState(0);

    function chageIndex(item){
      setcatIndex(item)
    }

    // modal of one product

    const [modalData, setModalData] = useState(dataProducts)
    const [modal, setModal] = useState(false)

    function showModal(item, category){         // modalni asosiy ekranda datasi bo'yicha ko'rsatish va datani qiriqish
      setModal(item)
      const newModalData = dataProducts.filter((post) => post.id == category)
      setModalData(newModalData)       
  }

  function closeModal(item){          // modalni asosiy ekranda olib tashlash hamda data tiklash
      setModal(item)        
      setModalData(dataProducts)
  }

  const [deleteLoad, setDeleteLoad] = useState(false);
  
  // window.addEventListener("load", () => {
  //   setTimeout(() => {
  //     setDeleteLoad(true)
  //   }, 3000);
  // })

  useEffect(() => {        
    setTimeout(() => {
      setDeleteLoad(true)
    }, 4000);
  }, [])

  return (
    <BrowserRouter>
      {!deleteLoad &&
        <div className={`loader`}>
          <div className="load"></div>
        </div>
      }
      <div className='App'>
        <ScrollToTop />
        <NavbarCopy chageIndex={chageIndex} dataBlogs={dataBlog} inputValue={inputValue} filterData={filterData} showModal={showModal} dataProd={search(dataProducts)}  dataBlog={search(dataBlog)} countLike={countLike} langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} />
        <Switch>
          <Route exact path="/">
            {
              english &&
              <HeaderInput dataBlog={search(dataBlog)} setCountLike={setCountLike} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} chageIndex={chageIndex} langTitle={langTitle} name={dataMenu.name_en} descript={dataMenu.description_en} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3}  dataProd={search(dataProducts)} inputValue={inputValue} filterData={filterData} /> 
            }
            {
              russian &&
              <HeaderInput dataBlog={search(dataBlog)} setCountLike={setCountLike} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} chageIndex={chageIndex} langTitle={langTitle} name={dataMenu.name_ru} descript={dataMenu.description_ru} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3}  dataProd={search(dataProducts)} inputValue={inputValue} filterData={filterData} /> 
            }
            {
              uzbek &&
              <HeaderInput dataBlog={search(dataBlog)} setCountLike={setCountLike} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} chageIndex={chageIndex} langTitle={langTitle} name={dataMenu.name_uz} descript={dataMenu.description_uz} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3}  dataProd={search(dataProducts)} inputValue={inputValue} filterData={filterData} /> 
            }

             <Products chageIndex={chageIndex} setCountLike={setCountLike} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} catIndex={catIndex} addCountLike={addCountLike} deletCountLike={deletCountLike} english={english} russian={russian} uzbek={uzbek} best={true} all={false} saved={false} dataProducts={dataProducts} dataMenu={dataMenu} dataTip={dataTip} showHandleBuy={showHandleBuy} />
            
            {
              english &&
              <Sofa chageIndex={chageIndex} langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataMenu.name_en} descript={dataMenu.description_en} />
            }
            {
              russian &&
              <Sofa chageIndex={chageIndex} langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataMenu.name_ru} descript={dataMenu.description_ru} />
            }
            {
              uzbek &&
              <Sofa chageIndex={chageIndex} langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataMenu.name_uz} descript={dataMenu.description_uz} />
            }

            <Gradients />


            {
              english &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_en} descript={dataBlog.description_en} />
            }
            {
              russian &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_ru} descript={dataBlog.description_ru} />
            }
            {
              uzbek &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_uz} descript={dataBlog.description_uz} />
            }
            {
              english &&
              <Client langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} name={dataMenus.name_en} descript={dataMenus.description_en}  />
            }
            {
              russian &&
              <Client langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} name={dataMenus.name_ru} descript={dataMenus.description_ru}  />
            }
            {
              uzbek &&
              <Client langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} name={dataMenus.name_uz} descript={dataMenus.description_uz}  />
            }

            <Country />
            <Footer english={english} russian={russian} uzbek={uzbek} />
          </Route>
          <Route  path='/saved'>
            <Products setCountLike={setCountLike} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} catIndex={catIndex} addCountLike={addCountLike} deletCountLike={deletCountLike}english={english} russian={russian} uzbek={uzbek} best={false} all={false} saved={true} dataProducts={dataProducts} dataMenu={dataMenu} dataTip={dataTip} showHandleBuy={showHandleBuy} />
          </Route>          
          <Route path='/products'>
            <Products setCountLike={setCountLike} modalData={modalData} modal={modal} showModal={showModal} closeModal={closeModal} setModal={setModal} catIndex={catIndex} addCountLike={addCountLike} deletCountLike={deletCountLike}english={english} russian={russian} uzbek={uzbek} best={false} all={true} saved={false} dataProducts={dataProducts} dataMenu={dataMenu} dataTip={dataTip} showHandleBuy={showHandleBuy} />
            <Gradients />
            {
              english &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_en} descript={dataBlog.description_en} />
            }
            {
              russian &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_ru} descript={dataBlog.description_ru} />
            }
            {
              uzbek &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_uz} descript={dataBlog.description_uz} />
            }
             {
              english &&
              <Client langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} name={dataMenus.name_en} descript={dataMenus.description_en}  />
            }
            {
              russian &&
              <Client langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} name={dataMenus.name_ru} descript={dataMenus.description_ru}  />
            }
            {
              uzbek &&
              <Client langTitle={langTitle} english={english} russian={russian} uzbek={uzbek} change1={change1} change2={change2} change3={change3} name={dataMenus.name_uz} descript={dataMenus.description_uz}  />
            }
            <Footer english={english} russian={russian} uzbek={uzbek} />
          </Route>
          <Route path='/catagory1'>
              <Catagory1 chageIndex={chageIndex} />
          </Route>
          <Route  path="/catagory2">
              <Catagory2 chageIndex={chageIndex} />
          </Route>
          <Route path="/catagory3">
              <Catagory3 chageIndex={chageIndex} />
          </Route>
          <Route path="/about">
            <AboutPage langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} />
            <Gradients />
            {
              english &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_en} descript={dataBlog.description_en} />
            }
            {
              russian &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_ru} descript={dataBlog.description_ru} />
            }
            {
              uzbek &&
              <SliderBox langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_uz} descript={dataBlog.description_uz} />
            }
            <Footer english={english} russian={russian} uzbek={uzbek} />
          </Route>
          {dataBlog.map((item) => (
              <Route path={`/blog${item.id}`} key={item.id} >
                {english &&
                  <BlogPage english={english} russian={russian} uzbek={uzbek} img={item.image} name={item.name_en} description={item.description_en} change1={change1} change2={change2} change3={change3} />
                }
                {russian &&
                  <BlogPage english={english} russian={russian} uzbek={uzbek} img={item.image} name={item.name_ru} description={item.description_ru} change1={change1} change2={change2} change3={change3} />
                }
                {uzbek &&
                  <BlogPage english={english} russian={russian} uzbek={uzbek} img={item.image} name={item.name_uz} description={item.description_uz} change1={change1} change2={change2} change3={change3} />
                }
                {
                  english &&
                  <SliderBox blogPost={true} langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_en} descript={dataBlog.description_en} />
                }
                {
                  russian &&
                  <SliderBox blogPost={true} langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_ru} descript={dataBlog.description_ru} />
                }
                {
                  uzbek &&
                  <SliderBox blogPost={true} langTitle={langTitle} english={english} change1={change1} change2={change2} change3={change3} russian={russian} uzbek={uzbek} name={dataBlog.name_uz} descript={dataBlog.description_uz} />
                }
                <Footer english={english} russian={russian} uzbek={uzbek} />
              </Route>
            ))}
        </Switch>        
      </div>
    </BrowserRouter>
  );
};

export default App;