import React, { useEffect, useState } from "react";
import obj from "../house";
import './Slider.scss'
import axios from "axios";
import button from '../../Assets/img/button.png'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Slider from "react-slick";


function SliderBox({ uzbek, russian, english, blogPost }) {

    const settings = {
        dots: true,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "17%",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    const [count, setCount] = useState(0)

    const handlerRightBtn = () => {
        setCount(count + 1)
    }

    const handlerLeftBtn = () => {
        setCount(count - 1);
    }

    const [dataBlog, setDataBlog] = useState([]);
    const urlBlog = `https://api.honor-furniture.uz/blogs/`;

    useEffect(() => {
        async function fetchData() {
            const request = await axios.get(urlBlog);
            setDataBlog(request.data);
            return request;
        };
        fetchData()
    }, [urlBlog]);

    const { t } = useTranslation()



    return (
        <div id="blogs" className={`slider ${blogPost && "blog-slider"}`}>
            <div className="slider__leftBox">
                <div className="slider__infoBox infos">
                    <h2 className="infos__title">{t("there")}</h2>
                    <p className="infos__text">{t("theretext")} </p>
                    <Link to="/about" className="infos__link" >{t("readmore")} <i className="fa fa-arrow-right"></i></Link>
                </div>
            </div>
            <div className="slider__rightBox">
                <button onClick={handlerRightBtn} className="slider__rightBtn" style={{ display: `${(count !== obj.length + 3) ? "block" : "none"}` }}>
                    <img src={button} alt="" className="slider__button-img" />
                </button>
                <button onClick={handlerLeftBtn} className="slider__leftBtn" style={{ display: `${(count === 0) ? "none" : "block"}` }}>
                    <img src={button} alt="" className="slider__button-img" />
                </button>
                <ul style={{ transform: `translateX(${-count * 300}px)` }} className="slider__list">
                    {dataBlog && dataBlog.map((item) => (
                        <li className="slider__item" key={item.id}>
                            <Link to={`/blog${item.id}`}>
                                <img className="slider__img" src={item.image} />
                                <div className="slider__desc">
                                    {
                                        english &&
                                        <p className="slider__title">{item.name_en}</p>
                                    }
                                    {
                                        russian &&
                                        <p className="slider__title">{item.name_ru}</p>
                                    }
                                    {
                                        uzbek &&
                                        <p className="slider__title">{item.name_uz}</p>
                                    }
                                    {
                                        english &&
                                        <p className="slider__titles">{item.description_en}</p>
                                    }
                                    {
                                        russian &&
                                        <p className="slider__titles">{item.description_ru}</p>
                                    }
                                    {
                                        uzbek &&
                                        <p className="slider__titles">{item.description_uz}</p>
                                    }
                                    <Link to={`/blog${item.id}`} className="slider__link">{t("readmore")}</Link>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>

                <Slider {...settings} className="carousel">
                    {dataBlog && dataBlog.map((data) => (
                        <Link to={`/blog${data.id}`} key={data.id} className="slider1">
                            <img src={data.image} alt="" />
                            <div className="text">
                                <h3 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h3>
                                <p className="name">{english && data.description_en} {russian && data.description_ru} {uzbek && data.description_uz}</p>
                            </div>
                        </Link>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default SliderBox;