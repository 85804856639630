import { useEffect, useState } from 'react'
import './Blog.scss'
import axios from 'axios';


const Blog = ({name, img, description}) => {


    const [dataBlog, setDataBlog] = useState([]);
    const urlBlog = `https://api.honor-furniture.uz/blogs/`;
  
    useEffect(() => {
      async function fetchData() {
        const request = await axios.get(urlBlog);
        setDataBlog(request.data);
        return request;
      };
      fetchData()
    }, [urlBlog]);

    return(
        <div className="blog">
            <div className="container">
                <div className="blog__page">
                    <h2 className="blog__name">{name}</h2>
                    <div className="blog__title"><img src={img} alt="" className="blog__img" /></div>
                    <div className="icons">
                        <i className="fa fa-user-o"></i>
                        <p>Ziynat Mebel</p>
                    </div>
                    <p className="blog__text">{description}</p>
                </div>
            </div>
        </div>
    )
}
export default Blog