import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Scss/Products.scss';
import Img1 from './images/bedroom.png';
import Img2 from './images/chair.png';
import Img3 from './images/sofa.png';
import Img4 from './images/sofa1.png';
import Img5 from './images/prod.png';
import Img6 from './images/prod2.png';
import Img7 from './images/prod3.png';
import button from '../../Assets/img/button.png'
import { useState } from 'react';
import Slider from 'react-slick';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useEffect } from 'react';
import axios from 'axios'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import BtnSlider from '../BtnSlider';
import { VisibilityOutlined } from '@mui/icons-material';
import ntc from 'ntcjs';




const Products = ({chageIndex, setCountLike, setModal, modal, modalData, showModal, closeModal, catIndex, addCountLike, deletCountLike, english, uzbek, russian, dataProducts, best, dataMenu, dataTip, saved, all}) => {    


    //i18

    const {t} = useTranslation()

    // const bestProds = best ? subTips == data.category && prods == data.sub_category : true
    // const savedProds = saved ? numberLikes != 0 && numberLikes : true

    // slider carousel settings

    const settings = {
        className: "slider variable-width",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
    };

    // categories

    let radio = document.querySelectorAll(".radio")  
    
    for (let i = 0; i < radio.length; i++) {
        radio[0].setAttribute("checked", "true")
    }    

    // tips of products

    const [subTips, setSubTips] = useState(saved ? 0 : catIndex)
    const [allLikesProd, setAllLikesProd] = useState(true)
    const [firstHeight, setfirstHeight] = useState(window.innerWidth < 410 ? 430 : window.innerWidth < 767 ? 530 : window.innerWidth < 1100 ? 350 : 420);
    const [allBest, setAllBest] = useState(true)

    // useEffect(() => {
    //     if(window.innerWidth < 1100 ){
    //         setfirstHeight(350)
    //     }
    //     if(window.innerWidth < 767 ){
    //         setfirstHeight(530)
    //     }
    //     if(window.innerWidth < 410 ){
    //         setfirstHeight(430)
    //     }       
    // }, [])

    const [height, setHeight] = useState(firstHeight)

    // products 

    const [prods, setProds] = useState(0)

    //Slider

    const [slideIndex, setSlideIndex] = useState(0)

    const nextSlide = () => {
        if(slideIndex+4 < dataProducts.filter((c) => c.best == true && subTips == c.category || c.best == true && allBest && subTips !== c.category).length){
            setSlideIndex(slideIndex + 1)
        }         
    }

    const prevSlide = () => {
        if(slideIndex > 0){
            setSlideIndex(slideIndex - 1)
        }
    }

    function changeProducts(item){          // subcategoriyalardan birortasini tanlaganda productlar o'zgarishi
        setProds(item)
        setHeight(firstHeight)
    }

    function countHeight(){
        setHeight(height + firstHeight)
    }

    function changeTips(item){          // categoriyalardan birortasini tanlaganda subCategoriyalar o'zgarishi
        setSubTips(item)
        setAllLikesProd(false)
        setHeight(firstHeight)
        setProds(0)
        setAllBest(false)
        setSlideIndex(0)
    }

    function changeTipsAll(item){
        setAllBest(true)
        setSubTips(item)
        setAllLikesProd(false)
        setHeight(firstHeight)
        setProds(0)
        setSlideIndex(0)
    }

    const [subTips1, setSubTips1] = useState(0)

    function changeTips1(item){          // categoriyalardan birortasini tanlaganda subCategoriyalar o'zgarishi
        setSubTips1(item)
        setAllLikesProd(false)
    }

    let tipology = document.querySelectorAll(".tipology")  
    
    for (let i = 0; i < tipology.length; i++) {
        tipology[0].setAttribute("checked", "true")
    }

     // like algorithms

    const [numberLikes, setNumberLikes] = useState(localStorage.getItem("like") ? localStorage.getItem("like").split(',') : 0)  
    const [like, setLike] = useState(false)    
    const [likes, setLikes] = useState(localStorage.getItem("lk") ? localStorage.getItem("lk").split(',') : [])

    function getLikes(item){
        setLike(!like)       
        setLikes([...likes, item])
        localStorage.setItem("lk", [likes,item])
    }        

    function unlikes(item){
        const arrLk = likes.filter((inc) => inc != item)
        setLikes(arrLk)
        localStorage.setItem("lk", arrLk)          
    }

    // modal

    // const [modalData, setModalData] = useState(dataProducts)
    // const [modal, setModal] = useState(false)
    const [indexImg, setIndexImg] = useState(0)
    const [indexFile, setIndexFile] = useState(0)

    const img = modalData.map((c) => c.image)
    const img1 = modalData.map((c) => c.image1)
    const img2 = modalData.map((c) => c.image2)
    const img3 = modalData.map((c) => c.image3)
    const img4 = modalData.map((c) => c.image4)
    const otherImgs = [img, img1, img2, img3, img4]

    const [tour, setTour] = useState(false)
    const color1 = modalData.map((c) => c.color1)
    const color2 = modalData.map((c) => c.color2)
    const color3 = modalData.map((c) => c.color3)
    const colors = [color1, color2, color3]    
    const file1 = modalData.map((c) => c.file1)
    const file2 = modalData.map((c) => c.file2)
    const file3 = modalData.map((c) => c.file3)
    const files = [file1, file2, file3]    

    // function showModal(item, category){         // modalni asosiy ekranda datasi bo'yicha ko'rsatish va datani qiriqish
    //     setModal(item)
    //     const newModalData = dataProducts.filter((post) => post.id == category)
    //     setModalData(newModalData)       
    // }

    // function closeModal(item){          // modalni asosiy ekranda olib tashlash hamda data tiklash
    //     setModal(item)        
    //     setModalData(dataProducts)
    // }

    function changeImg(c, e){      // modalning ichidagi rasmlarni o'zgartirish funksiyasi
        setIndexImg(c.indexOf(e))
        setTour(false)
    }

    const [colorProd, setColorProd] = useState(localStorage.getItem("color") ? JSON.parse(localStorage.getItem("color").split(',')) : [])

    function changeFile(c, e){      // modalning ichidagi 3D rasmlarni o'zgartirish funksiyasi        
        setIndexFile(c.indexOf(e))
    }

    function getColor(item, id){     
        const match = ntc.name(item.toString())  
        if(colorProd.filter((j) => j.id !== id && j.color !== match)){
            setColorProd(colorProd != [] ? [...colorProd.filter((c) => c.id != id), {id: id, color: match[1]}] : {id: id, color: match[1]})
            localStorage.setItem("color", JSON.stringify(colorProd != [] ? [...colorProd.filter((c) => c.id != id), {id: id, color: match[1]}] : {id: id, color: match[1]}))                
        }       
    }  
    console.log(colorProd)
    
    function deleteColor(item, id){     
        const newColor = colorProd.filter((c) => c.id != id)         
        setColorProd(newColor)
        localStorage.setItem("color", JSON.stringify(newColor))                
    }  


    // buy products ----------------------------------------------

    const [showBuy, setShowBuy] = useState(false)

    function showHandleBuy(item){
        setShowBuy(item)
    }

    function openBuyModal(){
        setModal(false)
        showHandleBuy(true)
    }

    // location methods ---------------------------------------

    const apiLocation = `https://api.openweathermap.org/data/2.5/onecall?`
    const apiKey = `4c28cc666e8471d7a6a57d442f1fedb0` 
    const [locationData, setLocationData] = useState()

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {       
            let urlLoc = `${apiLocation}lat=${position.coords.latitude}&lon=${position.coords.longitude}&exclude=hourly,daily&appid=${apiKey}`
            axios.get(urlLoc).then((response) => setLocationData(response.data.timezone))
        })
    }, [])

    // bot methods ---------------------------------------

    const [nameValue, setNameValue] = useState("")
    const [numberValue, setNumberValue] = useState("")   
    const [allPrice, setAllPrice] = useState(120)
    const [productNames, setProductNames] = useState("")
    const [payValue, setPayValue] = useState("")  
    const [delivery, setDelivery] = useState("")  
    const [redName, setRedName] = useState(false);
    const [redNumber, setRedNumber] = useState(false) 
    const [redLocation, setRedLocation] = useState(false) 
    const [locationValue, setLocationValue] = useState("");

    let newPro = []
    let newPrice = []
    let nbm = 0
    let cls = []

    likes != 0 && likes.forEach((data) => {
        newPro.push(dataProducts.filter((item) => item.id == data ? item.name_en : null))              // sotib olinayotgan mahsulotlar nomi
        newPrice.push(dataProducts.map((item) => item.id == data ? item.price : 0))           // sotib olinayotgan mahsulotlar narxlari
        cls.push(colorProd.filter((item) => item.id == data))
    })

    // let newPro = dataProducts.filter((item) => likes.toString().includes(item.id) && item.name_en) 

    newPro.forEach((c) => {
        c.forEach((k) => {
            nbm += k.price                    // umumiy summani hisoblash
        })
    })

    function changePayment(item){
        setPayValue(item)
        setIsActive(current => !current);
    }       

    function findLocation(){
        setDelivery(locationData)
    }

    function handleChange1(item){
        setNameValue(item)
        setRedName(false)
    }
    function handleChange2(item){
        setNumberValue(item)
        setRedNumber(false)
    }   
    function handleChange3(item){
        setLocationValue(item)
        setRedLocation(false)
    }    

    const [modalOk, setModalOk] = useState(true)

    function changeModal(){
        setModalOk(false)
        setShowBuy(false)
    }
    
    let bot = {
        TOKEN: "5568615804:AAHPwxSUQSH2POp4xOAdWgcuqZrul87tNJ8",
        chatID: "-1001554937927",
        message: `Ism: ${nameValue}; %0ARaqam: ${numberValue}; %0AUmumiy summa: ${nbm} so'm; %0AHudud: ${locationValue}; %0AMahsulotlar: ${newPro.map((c) => c.map((k) => `${k.name_en} dan ${colorProd.filter((j) => j.id == k.id).map((x) => x.color)}`))}; %0ATo'lov turi: ${payValue};`  //${colorProd != [] ? colorProd.filter((j) => j.id == k.id).map((x) => x.color) : ''}
    }

    console.log(bot.message)

    function sendMessage(e){  
        
        if(locationValue === ""){           
            setRedLocation(true)        
        }else if(payValue === ""){           
            alert("Iltimos to'lov turini tanlang")        
        }else if(nameValue === ""){            
            setRedName(true)
        }else if(numberValue === ""){           
            setRedNumber(true)        
        }else{
            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method : "GET"
            })
            .then(success => {
                console.log('send message')
            }, error => {
                console.log(error)
            })

            setNameValue("")
            setNumberValue("")
            setPayValue("")
            setLocationValue("")
            changeModal()
            setTimeout(() => {      
                setLikes([])
                localStorage.removeItem("lk") 
                setCountLike(0) 
                setColorProd([])
                localStorage.removeItem("color")
            }, 1000);
        }
        
    }

    const [isActive, setIsActive] = useState(false);

    const [OP, setOP] = useState(false)

    function openProd(){
        setOP(!OP)
    }
    
    const [threeD, setthreeD] = useState(false);

    function show3D(item){
        setthreeD(!item)
        setTour(item
            )
    }

    function getModalLikes(item) {
        getLikes(item)
        addCountLike(item)
    }

    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    function showModalWith3D(item, c){
        showModal(item, c)
        show3D(true)
    }

    function changeAllLikes(){
        setSubTips1(0)
        setAllLikesProd(true)
    }

    return (
        <>
            <div className='Products'>
                <div className="wrapper row" onClick={() => changeAllLikes()}>
                
                    {/* all products */}

                    {!best && !saved &&
                        <div className="col-md-12 menu-catalog d-flex">
                            {dataMenu.map((menu) => {
                                return(
                                    <div key={menu.id} className='catalog-menu'>
                                        <input checked={menu.id==subTips} type="radio" className="form-check-input radio" onChange={() => changeTips(menu.id)}  name="flexRadioDefault" id={`radio${menu.id+1}`} />
                                        <label htmlFor={`radio${menu.id+1}`}  >
                                            <img src={menu.icon} alt="" />
                                        </label>
                                    </div>
                                )
                            })}                    
                        </div>
                    }
                    {!best && !all &&
                        <div className="col-md-12 menu-catalog d-flex">
                            {dataMenu.map((menu) => {
                                return(
                                    <div key={menu.id} className='catalog-menu'>
                                        <input type="radio" className="form-check-input radio" checked={subTips1 == menu.id} onChange={() => changeTips1(menu.id)}  name="flexRadioDefault" id={`radio${menu.id+1}`} />
                                        <label htmlFor={`radio${menu.id+1}`}  >
                                            <img src={menu.icon} alt="" />
                                        </label>
                                    </div>
                                )
                            })}                    
                        </div>
                    }

                    {!best && !saved &&
                        <div className='col-md-12 tip-catalog'>
                            {dataTip.filter((c) => c.category == subTips).map((tip) => {
                                return(
                                    // subTips == tip.category &&
                                    <div className="tip" key={tip.id}>
                                        <input checked={dataTip.filter((c) => c.category == subTips).indexOf(tip)==prods} type="radio" className="form-check-input tipology" name="flexRadioDefault1"  onChange={() => changeProducts(dataTip.filter((c) => c.category == subTips).indexOf(tip))} id={`tip${tip.id-1}`} />
                                        <label htmlFor={`tip${tip.id-1}`}>
                                            {
                                                english &&
                                                <p>{tip.name_en}</p>
                                            }
                                            {
                                                russian &&
                                                <p>{tip.name_ru}</p>
                                            }
                                            {
                                                uzbek &&
                                                <p>{tip.name_uz}</p>
                                            }
                                        </label>
                                    </div>
                                )
                            })}                    
                        </div>
                    }
                       
                    {all && !best && !saved &&
                        <div className="products row" style={{maxHeight: `${height}px`, overflow: 'hidden'}}>                    
                            {dataProducts.map((data, index) => {
                                return(                                                                        
                                    <div key={data.id} className={`col-3 prod ${subTips == data.category && dataTip.filter((c) => c.category == subTips)[prods].id == data.sub_category && "middle-main"}`} >
                                        <div className="body">
                                            <div className="img-list" onClick={() => showModal(true, data.id)}>
                                                <img src={data.image} alt="" />
                                            </div>
                                            <p className='res-name'>{uzbek && data.name_uz} {english && data.name_en} {russian && data.name_ru}</p>
                                            <div className="title">                                                
                                                <span className="prod-names">
                                                    {
                                                        english &&
                                                        <p>{data.name_en}</p>
                                                    }
                                                    {
                                                        russian &&
                                                        <p>{data.name_ru}</p>
                                                    }
                                                    {
                                                        uzbek &&
                                                        <p>{data.name_uz}</p>
                                                    }
                                                    <h4>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h4>                                                                                    
                                                </span>  
                                                <h5 className='res-price'>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h5>  
                                                {data.file_id && <VisibilityOutlined onClick={() => showModalWith3D(true, data.id)} className="eye-icon" />}                                                       
                                                {likes.toString().includes(data.id) &&
                                                    <input type="checkbox" checked onChange={() => unlikes(data.id)} className={`checkbox`} id={`cc${data.id+1}`} /> 
                                                }
                                                {!likes.toString().includes(data.id) &&
                                                    <input type="checkbox" onChange={() => getLikes(data.id)} className={`checkbox`} id={`cc${data.id+1}`} />                                                                                       
                                                }
                                                <label htmlFor={`cc${data.id+1}`}>                                                
                                                    <i onClick={() => addCountLike(data.id)} className={`fa fa-heart-o`}></i> 
                                                    <i onClick={() => deletCountLike(data.id)} className={`fa fa-heart`}></i>                                                                                              
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                                                    
                                )
                            })}
                        </div>
                    }

                    {all &&
                        <div className={`show-more col-md-12`} >                                
                            {dataProducts.map((data, index) => {
                                return(              
                                    subTips == data.category && dataTip.filter((c) => c.category == subTips)[prods].id == data.sub_category &&                                                          
                                    <button key={data.id} className="btn" onClick={() => countHeight()}>{t("show")}</button>
                                    )
                                }) }                       
                        </div>                                                                    
                    }

                    {/* saved products */}

                    {!all && !best &&
                        <div className='col-md-12 tip-catalog'>
                            <div className="tip">
                                <input type="radio" className="form-check-input tipology" checked name="flexRadioDefault1" id={`tip@`} />
                                <label htmlFor={`tip@`}  >
                                    <p>{t("saved")}</p>
                                </label>
                            </div>                 
                        </div>
                    }
                    
                    {saved && !best && !all &&
                        <div className="products row">                    
                            {dataProducts.map((data, index) => {
                                return(                                                                         
                                    <div key={data.id} className={`col-3 prod ${likes.toString().includes(data.id) && subTips1 == data.category && !allLikesProd && "d-block"} ${likes.toString().includes(data.id) && subTips1 !== data.category && allLikesProd && "d-block"}`} >
                                        <div className="body">
                                            <div className="img-list" onClick={() => showModal(true, data.id)}>
                                                <img src={data.image} alt="" />
                                            </div>
                                            <p className='res-name'>{uzbek && data.name_uz} {english && data.name_en} {russian && data.name_ru}</p>
                                            <div className="title">                                                
                                                <span className="prod-names">
                                                    {
                                                        english &&
                                                        <p>{data.name_en}</p>
                                                    }
                                                    {
                                                        russian &&
                                                        <p>{data.name_ru}</p>
                                                    }
                                                    {
                                                        uzbek &&
                                                        <p>{data.name_uz}</p>
                                                    }
                                                    <h4>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h4>                                
                                                </span>  
                                                <h5 className='res-price'>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h5>          
                                                {data.file_id && <VisibilityOutlined onClick={() => showModalWith3D(true, data.id)} className="eye-icon" />}                                                                                                                                                                                     
                                                {likes.toString().includes(data.id) &&
                                                    <input type="checkbox" checked onChange={() => unlikes(data.id)} className={`checkbox`} id={`cc${data.id+1}`} /> 
                                                }
                                                {!likes.toString().includes(data.id) &&
                                                    <input type="checkbox" onChange={() => getLikes(data.id)} className={`checkbox`} id={`cc${data.id+1}`} />                                                                                       
                                                }
                                                <label htmlFor={`cc${data.id+1}`}>                                                
                                                    <i onClick={() => addCountLike(data.id)} className={`fa fa-heart-o`}></i> 
                                                    <i onClick={() => deletCountLike(data.id)} className={`fa fa-heart`}></i>                                                                                              
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                                                                                  
                                )
                            })}
                        </div>
                    }
                    {saved &&
                        <div className='all-buy col-md-12'>
                            <button className='btn' onClick={() => openBuyModal()}>{t("buy")}</button>
                        </div>
                    }

                    {/* best products */}

                    {!all && !saved &&
                        <h1 id="products" className='best-prod text-center'>{t("bestsale")}</h1> 
                    }

                    {best &&
                        <div className="col-md-12 tip-catalog d-flex" id='best'>
                            <div className='tip'>
                                <input type="radio" className="form-check-input tipology" onChange={() => changeTipsAll(0)} checked={0==subTips} name="flexRadioDefault" id={`radio@@`} />
                                <label className='border-0'  htmlFor={`radio@@`}>
                                    <h6 className='product--name'>{t("all")}</h6>                                                                    
                                </label>
                            </div>
                            {dataMenu.map((menu) => {
                                return(
                                    <div key={menu.id} className='tip'>
                                        <input type="radio" className="form-check-input tipology" onChange={() => changeTips(menu.id)} checked={menu.id==subTips} name="flexRadioDefault" id={`radio${menu.id+1}`} />
                                        <label   htmlFor={`radio${menu.id+1}`}>
                                            {
                                                english &&
                                                <h6 className='product--name'>{menu.name_en}</h6>
                                            }
                                            {
                                                russian &&
                                                <h6 className='product--name'>{menu.name_ru}</h6>
                                            }
                                            {
                                                uzbek &&
                                                <h6 className='product--name'>{menu.name_uz}</h6>
                                            }
                                        </label>
                                    </div>
                                )
                            })}                    
                        </div>
                    }

                    {best && !saved && !all &&
                        <div className="products row" >
                            <BtnSlider moveSlide={nextSlide} direction={"next"} className="next-btn" />
                            <BtnSlider moveSlide={prevSlide} direction={"prev"}/>
                            {dataProducts.filter((c) => c.best == true && subTips == c.category || c.best == true && allBest && subTips !== c.category).slice(slideIndex,slideIndex+4).map((data, index) => {
                                return(                                                                                        
                                    <div key={data.id} className={`prod col-3 d-block`} >
                                        <div className="body">
                                            <div className="img-list" onClick={() => showModal(true, data.id)}>
                                                <img src={data.image} alt="" />
                                            </div>
                                            <p className='res-name'>{uzbek && data.name_uz} {english && data.name_en} {russian && data.name_ru}</p>
                                            <div className="title">                                                
                                                <span className="prod-names">
                                                    {
                                                        english &&
                                                        <p>{data.name_en}</p>
                                                    }
                                                    {
                                                        russian &&
                                                        <p>{data.name_ru}</p>
                                                    }
                                                    {
                                                        uzbek &&
                                                        <p>{data.name_uz}</p>
                                                    }
                                                    <h4>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h4>                                
                                                </span>  
                                                <h5 className='res-price'>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h5>  
                                                {data.file_id && <VisibilityOutlined onClick={() => showModalWith3D(true, data.id)} className="eye-icon" />}                                                    
                                                {likes.toString().includes(data.id) &&
                                                    <input type="checkbox" checked onChange={() => unlikes(data.id)} className={`checkbox`} id={`cc${data.id+1}`} /> 
                                                }
                                                {!likes.toString().includes(data.id) &&
                                                    <input type="checkbox" onChange={() => getLikes(data.id)} className={`checkbox`} id={`cc${data.id+1}`} />                                                                                       
                                                }
                                                <label htmlFor={`cc${data.id+1}`}>                                                
                                                    <i onClick={() => addCountLike(data.id)} className={`fa fa-heart-o`}></i> 
                                                    <i onClick={() => deletCountLike(data.id)} className={`fa fa-heart`}></i>                                                                                              
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                                                                                                                              
                                )
                            })}
                        </div>                        
                    }

                    {/* modal for one product */}

                    {modalData.map((data) => {
                        return(
                            <div key={data.id} className={`one-product col-md-12 ${!modal && "d-none"}`}>                                                          
                                <i className="fa fa-times"  onClick={() => closeModal(false)}></i>
                                <div className="col-md-6 imgs">
                                    <div className="body row">
                                        {!tour && otherImgs.slice(indexImg, indexImg+1).map((p, index) => {
                                            return(
                                                <div key={index} className="img-list col-12">
                                                    <div className="body">
                                                        <img src={p} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {tour && files.slice(indexFile, indexFile+1).map((p, index) => {
                                            return(
                                                <div key={index} className="img-list col-12">
                                                    <div className="body" onClick={() => setthreeD(true)}>
                                                        <span className={`a3D ${threeD && "d-none"}`}>3D <i className="fa fa-play"></i></span>
                                                        <div class="sketchfab-embed-wrapper"> <iframe title="VITRA Lounge Chair" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src={`https://sketchfab.com/models/${data.file_id}/embed?autostart=1&ui_infos=0&ui_watermark_link=0&ui_watermark=0`}> </iframe> </div>
                                                        {/* <img src={p} alt="" /> */}
                                                    </div>
                                                </div>
                                            )
                                        })}                                                
                                        {otherImgs.map((p, index) => {
                                            return(
                                                <div key={index} className="other">
                                                    <div className="body" onClick={() => changeImg(otherImgs, p)}>
                                                        <img src={p} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })}  
                                        {data.file_id &&
                                            <div className={`other`}>
                                                <div className={`body ${tour && "bg-dark text-white"}`} onClick={() => show3D(true)}>
                                                    <p>360 &#176;</p>                                            
                                                </div>                                
                                            </div>
                                        }                                  
                                    </div>
                                </div>
                                <div className="col-md-5 texts">
                                    <div className="body">
                                        <h1>{data.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h1>
                                        <div className="d-flex">
                                            <div className="colors">
                                                {colors.slice(0,1).map((c,index) => (                                                    
                                                    <>                                                        
                                                        {/* {
                                                            colorProd.length == 0 ? <input className='color-radio' type="checkbox" name='colorss' id={`${c}`}  onChange={() => getColor(c, data.id)} />:
                                                            colorProd.filter((k) => k.id == data.id).map((j) => (  
                                                                ntc.name(c.toString())[1] == j.color ?                                                          
                                                                <input checked className='color-radio' type="checkbox" name='colorss' id={`${c}`}  onChange={() => deleteColor(c, data.id)} />:
                                                                <input className='color-radio' type="checkbox" name='colorss' id={`${c}`}  onChange={() => getColor(c, data.id)} />
                                                            ))
                                                        } */}
                                                        <input className='color-radio' type="radio" name='colorss' id={`${c}`}  onChange={() => getColor(c, data.id)} />
                                                        <label htmlFor={`${c}`} key={index} className={`cls`} style={{backgroundColor: `${c}`}}></label>
                                                    </>
                                                ))}                                            
                                            </div>
                                            <div className="colors">
                                                {colors.slice(1,2).map((c,index) => (
                                                    <>                                                        
                                                        {/* {
                                                            colorProd.length == 0 ? <input className='color-radio' type="checkbox" name='colorss' id={`${c}1`}  onChange={() => getColor(c, data.id)} />:
                                                            colorProd.filter((k) => k.id == data.id).map((j) => (  
                                                                ntc.name(c.toString())[1] == j.color ?                                                          
                                                                <input checked className='color-radio' type="checkbox" name='colorss' id={`${c}1`}  onChange={() => deleteColor(c, data.id)} />:
                                                                <input className='color-radio' type="checkbox" name='colorss' id={`${c}1`}  onChange={() => getColor(c, data.id)} />
                                                            ))
                                                        } */}
                                                        <input className='color-radio' type="radio" name='colorss' id={`${c}1`}  onChange={() => getColor(c, data.id)} />
                                                        <label htmlFor={`${c}1`} key={index} className={`cls`} style={{backgroundColor: `${c}`}}></label>
                                                    </>
                                                ))}                                            
                                            </div>
                                            <div className="colors">
                                                {colors.slice(2).map((c,index) => (
                                                    <>
                                                        {/* {
                                                            colorProd.length == 0 ? <input className='color-radio' type="checkbox" name='colorss' id={`${c}2`}  onChange={() => getColor(c, data.id)} />:
                                                            colorProd.filter((k) => k.id == data.id).map((j) => (  
                                                                ntc.name(c.toString())[1] == j.color ?                                                          
                                                                <input checked className='color-radio' type="checkbox" name='colorss' id={`${c}2`}  onChange={() => deleteColor(c, data.id)} />:
                                                                <input className='color-radio' type="checkbox" name='colorss' id={`${c}2`}  onChange={() => getColor(c, data.id)} />
                                                            ))
                                                        } */}
                                                        <input className='color-radio' type="radio" name='colorss' id={`${c}2`}  onChange={() => getColor(c, data.id)} />
                                                        <label htmlFor={`${c}2`} key={index} className={`cls`} style={{backgroundColor: `${c}`}}></label>
                                                    </>
                                                ))}                                            
                                            </div>                                          
                                        </div>
                                        <div>
                                            {/* {
                                                english &&
                                                <p className='products--text'>{data.description_en}</p>
                                            }
                                            {
                                                russian &&
                                                <p className='products--text'>{data.description_ru}</p>
                                            }
                                            {
                                                uzbek &&
                                                <p className='products--text'>{data.description_uz}</p>
                                            } */}
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary className='accordions'
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            >
                                            <Typography className='accordion-name' >
                                            {
                                                english &&
                                                <p className='products--texts'>{data.name_en}</p>
                                            }
                                            {
                                                russian &&
                                                <p className='products--texts'>{data.name_ru}</p>
                                            }
                                            {
                                                uzbek &&
                                                <p className='products--texts'>{data.name_uz}</p>
                                            }
                                            </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            <Typography>
                                            {
                                                english &&
                                                <p className='products--text'>{data.description_en}</p>
                                            }
                                            {
                                                russian &&
                                                <p className='products--text'>{data.description_ru}</p>
                                            }
                                            {
                                                uzbek &&
                                                <p className='products--text'>{data.description_uz}</p>
                                            }
                                            </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        </div>


                                        <div className="btns"> 
                                            {likes.toString().includes(data.id) &&
                                                <Link onClick={() => showModal(false)} className='buy btn' to="/saved">{t("buying")}</Link> 
                                            }
                                            {!likes.toString().includes(data.id) &&                                                    
                                                <label onClick={() => getModalLikes(data.id)} htmlFor={`cc${data.id+1}`}>                                                                                                                                               
                                                    <Link onClick={() => showModal(false)} className='buy btn' to="/saved">{t("buying")}</Link>                                                                                
                                                </label>                            
                                            }
                                            <button className='save btn'>                                                
                                                {likes.toString().includes(data.id) &&
                                                    <input type="checkbox" checked onChange={() => unlikes(data.id)} className={`checkbox1`} id={`cc${data.id+1}`} /> 
                                                }
                                                {!likes.toString().includes(data.id) &&
                                                    <input type="checkbox" onChange={() => getLikes(data.id)} className={`checkbox1`} id={`cc${data.id+1}`} />                                                                                       
                                                }
                                                <label htmlFor={`cc${data.id+1}`}>
                                                    {t("save")}                                                
                                                    <i onClick={() => addCountLike(data.id)} className={`fa fa-heart-o mx-2`}></i> 
                                                    <i onClick={() => deletCountLike(data.id)} className={`fa fa-heart mx-2`}></i>                                                                                              
                                                </label>
                                            </button>   
                                        </div>                                   
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={`contrast ${!modal && "d-none"}`} onClick={() => closeModal(false)}></div> 

                {/* modal for buy all products */}

                <div className={`buy-modal ${!showBuy && "d-none"}`}>
                    <i className='fa fa-times' onClick={() => showHandleBuy(false)}></i>
                    <h1>{t("verify")}</h1>                                              
                    <div className='row'>
                        <div className='col-md-6'>
                            <h1 className='price'>{t("cost")}: {nbm.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} {t("sum")}</h1>
                            <div className='col-md-12 faq'>
                                <div className='d-flex align-items-center justify-content-between'>                 
                                    <h5>{t("prods")}</h5>   
                                    <input type="checkbox" checked onChange={() => openProd()} className='rtprod' id="rtprod" />
                                    <label htmlFor="rtprod">
                                        <i className={`fa fa-chevron-down ${OP && "d-none"}`}></i>
                                        <i className={`fa fa-chevron-right ${!OP && "d-none"}`}></i>                
                                    </label>              
                                </div>                                
                                {dataProducts.map((item) => {
                                    return(    
                                        <div key={item.id} className={`${OP && "d-none"}`}>
                                            <div className={`answer ${!likes.toString().includes(item.id) && "d-none"} `}>
                                                <div className='body'>
                                                    <p className="title">{likes.indexOf(`${item.id}`)+2}. {english && item.name_en} {uzbek && item.name_uz} {russian && item.name_ru}</p>                   
                                                    <h6 className='m-0'>{item.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}{t("sum")}</h6>
                                                    <div className='d-flex'>
                                                        {/* <input type="checkbox" className='delet-checkbox' checked onChange={() => unLike(item.id)} id='delet' /> */}
                                                        <input type="checkbox" checked onChange={() => unlikes(item.id)} className={`delet-checkbox`} id={`delet${item.id+1}`} /> 
                                                        {/* {likes.toString().includes(item.id) &&
                                                        }
                                                        {!likes.toString().includes(item.id) &&
                                                            <input type="checkbox" onChange={() => getLikes(item.id)} className={`delet-checkbox`} id='delet' />                                                                                       
                                                        } */}
                                                        <label className='delet-prod' htmlFor={`delet${item.id+1}`} onClick={() => deletCountLike(item.id)}>x</label>
                                                        {/* <input type="checkbox" className='eye-checkbox' id='eye' /> */}
                                                        <label className='eye-prod mx-3' htmlFor='eye'>
                                                            <Visibility className='visible' onClick={() => showModal(true, item.id)} />
                                                            <VisibilityOff className='visibleoff' />
                                                        </label>                                                            
                                                    </div>
                                                </div>
                                            </div>                                                         
                                        </div>                                                                                      
                                        )
                                    })}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='col-md-12 faq'>
                                <div className='d-flex align-items-center justify-content-between'>                 
                                    <h5>{t("payment")}</h5>                 
                                    {/* <i className='fa fa-chevron-down'></i>                 */}
                                </div>                                
                                <div className='answer d-flex align-items-center justify-content-between'>
                                    <button className={`btn click ${payValue == "Click" && "bg-dark text-white"}`} value={`click`}  
                                     onClick={(e) => changePayment("Click")} >Click</button>
                                    <button className={`btn payme ${payValue == "PayMe" && "bg-dark text-white"}`}                                     
                                    value={`PayMe`} onClick={(e) => changePayment("PayMe")} >PayMe</button>
                                    <button className={`btn cash ${payValue == "Cash" && "bg-dark text-white"}`}  value={`Cash`}   onClick={(e) => changePayment("Cash")} >{t("cash")}</button>
                                    {/* <StripeCheckout /> */}
                                </div>                  
                            </div>
                            <div className='col-md-12 faq delivery'>
                                <div className='d-flex align-items-center justify-content-between'>                 
                                    <h5>{t("deliver")}</h5>                 
                                    {/* <i className='fa fa-chevron-down'></i>                 */}
                                </div>                                
                                <div className='answer d-fle align-items-start mt-2 justify-content-between'>
                                    {/* {delivery != "" &&
                                        <h4 className='border border-dark'>{delivery}</h4>
                                    } */}
                                    <label className='form-label' htmlFor="location">{english && "Location"} {uzbek && "Manzil"} {russian && "Aдрес"}</label>
                                    <input value={locationValue} onChange={(e) => handleChange3(e.target.value)} className={`${redLocation && "red-line"} form-control`} type="text" placeholder={english && "Location" || uzbek && "Manzil" || russian && "Aдрес"} />
                                    {/* <button className='btn location' onClick={() => findLocation()}>Location</button>                 */}
                                </div>                  
                            </div>
                        </div>
                        <div className='col-md-12 info-user'>
                            <div className='d-flex align-items-center justify-content-between'>                 
                                <h5>{t("info")}</h5>                 
                                {/* <i className='fa fa-chevron-down'></i>                 */}
                            </div>                                
                            <div className='row align-items-center justify-content-between'>
                                <div className="col-md-6 px-2">
                                    <label className='form-label' htmlFor='name'>{english && "Name"} {uzbek && "Ism"} {russian && "Имя"}</label>
                                    <input className={`${redName && "red-line"} form-control`} type="text" value={nameValue} onChange={(e) => handleChange1(e.target.value)} id='name' placeholder='Abdulhamid' />                
                                </div>
                                <div className="col-md-6 px-2">
                                    <label className='form-label d-block' htmlFor='number'>{english && "Telephone"} {uzbek && "Telefon"} {russian && "Tелефон"}</label>
                                    <input value={numberValue} placeholder='+99894 123 45 67' className={`form-control ${redNumber && "red-line"}`} onChange={(e) => handleChange2(e.target.value)} id="number" type="number" pattern='.{10,10}' required /> 
                                </div>
                            </div>                  
                        </div>
                    </div>
                    <div className='col-md-12 btns'>                              
                        <button className='btn' onClick={() => sendMessage()} >{t("ver")}</button>          
                    </div>
                </div>
                <div className={`contrast-buy ${!showBuy && "d-none"}`} onClick={() => showHandleBuy(false)}></div>

                <div className={`contrast ${modalOk && "d-none"}`} onClick={() => setModalOk(true)}></div>
                <div className={`col-md-6 offset-md-3 buy-ok ${!modalOk && "show-modal"}`}>
                    <i className='fa fa-times' onClick={() => setModalOk(true)} ></i> 
                    {english &&
                        <h1>THANK YOU FOR YOUR APPEAL &#128522;</h1> 
                    } 
                    {russian &&
                        <h1>СПАСИБО ЗА ОБРАЩЕНИЕ &#128522;</h1> 
                    } 
                    {uzbek &&
                        <h1>MUROJAATINGIZ UCHUN TASHAKKUR &#128522;</h1> 
                    }                                                 
                    <div className='col-md-4'>                    
                        {english && 
                            <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
                        }
                        {russian && 
                            <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
                        }
                        {uzbek && 
                            <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
                        }                       
                    </div>
                </div> 
            </div>           
        </>
    );
};

export default Products;