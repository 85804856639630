import './Sofa.scss'
import chair from '../../Assets/img/Tissue.png'
import chair1 from '../../Assets/img/west.png'
import chair2 from '../../Assets/img/wipes.png'
import chairr3 from '../../Assets/img/chairr3.png'
import chairs from '../../Assets/img/chairs.png'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios";


const Sofa = ({ uzbek, russian, english, chageIndex }) => {

    const [dataProducts, setDataProducts] = useState([]);
    const urlProducts = `https://api.honor-furniture.uz/categories/`;

    useEffect(() => {
        async function fetchData() {
            const request = await axios.get(urlProducts);
            setDataProducts(request.data);
            return request;
        };
        fetchData()
    }, [urlProducts]);


    return (
        <div id='categories' className="sofa">
            <h1 className='title'>{uzbek && "Toifalar"} {english && "Categories"} {russian && "Категории"}</h1>
            {/* <div className="container sofa--container mt-3">
                <div className="sofa--list">
                    {dataProducts.map((e) => (
                        <Link to="/products" key={e.id} className="sofa--link" onClick={() => chageIndex(e.id)} >
                            <div className="sofa--item">
                                <div className="hover-effect"></div>
                                <img src={e.category_picture} alt="" className="image" />
                                <div className="sofa--title">
                                    {english &&
                                        <h2 className="sofa--name">{e.name_en}</h2>
                                    }
                                    {russian &&
                                        <h2 className="sofa--name">{e.name_ru}</h2>
                                    }
                                    {uzbek &&
                                        <h2 className="sofa--name">{e.name_uz}</h2>
                                    }

                                    {english &&
                                        <p className="sofa--text">{e.description_en}</p>
                                    }
                                    {russian &&
                                        <p className="sofa--text">{e.description_ru}</p>
                                    }
                                    {uzbek &&
                                        <p className="sofa--text">{e.description_uz}</p>
                                    }
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div> */}
            <div className="wrapper">
                {dataProducts.map((e) => (
                    <Link to="/products" key={e.id} className="info" onClick={() => chageIndex(e.id)} >
                        <div className="hover-effect"></div>
                        <img src={e.category_picture} alt="" className="image" />
                        <div className="texts">
                            {english &&
                                <h2 className="s_name">{e.name_en}</h2>
                            }
                            {russian &&
                                <h2 className="s_name">{e.name_ru}</h2>
                            }
                            {uzbek &&
                                <h2 className="s_name">{e.name_uz}</h2>
                            }

                            {english &&
                                <p className="s_text">{e.description_en}</p>
                            }
                            {russian &&
                                <p className="s_text">{e.description_ru}</p>
                            }
                            {uzbek &&
                                <p className="s_text">{e.description_uz}</p>
                            }
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}
export default Sofa