import './AboutPage.scss'

import Navbar from '../../components/Navbar/Navbar'
import About from '../../components/About/About'
import SliderBox from '../../components/Slider/Slider'
import Footer from  '../../components/Footer/Footer'

const AboutPage = ({change1, change2, change3, uzbek, russian, english,}) => {
    return(
        <div className="aboutpage">
            {/* <Navbar /> */}
            <About change1={change1} change={change2} change3={change3} uzbek={uzbek} russian={russian} english={english} />
            {/* <SliderBox /> */}
            {/* <Footer /> */}
        </div>
    )
}

export default AboutPage