import { Close } from '@mui/icons-material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Iframe from 'react-iframe'
import './Gradient.scss'

const Gradients = () => {

    const [tour, setTour] = useState(true)

    const {t} = useTranslation()

    return(
        <div className="gradient" id='tour'>
            <div className={`contr-tour ${!tour && "d-none"}`} onClick={() => setTour(false)}>
                <p className="gradient__name">{t("tour")}</p>
                <h2 className="gradient__number">360&#176;</h2>
            </div>
            <Close className={`icon ${tour && "d-none"}`} onClick={() => setTour(true)} />
            {/* <Iframe className="virtual-iframe" url="https://roundme.com/embed/884870/2775036"
            id="myId"
            display="initial"
            position="relative"/> */}
            {/* <iframe className="virtual-iframe" src='https://roundme.com/embed/884870/2775036' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> */}
            <iframe className="virtual-iframe" src='https://roundme.com/embed/899685/2837383' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
    )
}

export default Gradients