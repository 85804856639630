import React, { useEffect, useRef, useState } from 'react';
import './Scss/Navbar.scss';
import { Link } from 'react-router-dom';
import Img1 from '../../Assets/img/telefon.png'
import Logo from './images/logo1.png';
import { useTranslation } from 'react-i18next';
import { DesktopMacTwoTone, Language, LocalPhone, LocalPhoneOutlined, Translate} from '@material-ui/icons';
import Navbar from '../Navbar/Navbar';
import Img2 from './images/search.png';
import { Launch, Search, VolumeOff, VolumeUp } from '@mui/icons-material';
import axios from 'axios';
import Audio from './audio/audio1.mp3';

const NavbarCopy = ({ chageIndex, countLike, white, english, uzbek, russian, change1, change2, change3, langTitle, dataBlogs, dataBlog, dataProd, showModal, inputValue, filterData }) => {

    window.addEventListener("scroll", function () {
        let navScroll = document.getElementById('nav');
        if (white) {
            navScroll.classList.toggle("white", window.scrollY > 10);
        } else if (!white) {
            navScroll.classList.toggle("black", window.scrollY > 10);
        }
    });

    const [show, setShow] = useState(false)

    const [language, setLanguage] = useState(false)
        
    function changeHandle1(){        
        change1(true)
        setLanguage(!language)
    }
    function changeHandle2(){
        change2(true)
        setLanguage(!language)
    }
    function changeHandle3(){
        change3(true)
        setLanguage(!language)
    }           

    const {t} = useTranslation()

    const [numberLikes, setNumberLikes] = useState(localStorage.getItem("like") ? localStorage.getItem("like").split(',') : 0)

    function loadWindow(item){
        window.location.reload(false)
    }

    const [dataProducts, setDataProducts] = useState([]);
    const urlProducts = `https://api.honor-furniture.uz/categories/`;
  
    useEffect(() => {
      async function fetchData() {
        const request = await axios.get(urlProducts);
        setDataProducts(request.data);
        return request;
      };
      fetchData()
    }, [urlProducts]);

    const [dataProds, setDataProds] = useState([]);
    const urlProds = `https://api.honor-furniture.uz/products/`;
  
    useEffect(() => {
      async function fetchData() {
        const request = await axios.get(urlProds);
        setDataProds(request.data);
        return request;
      };
      fetchData()
    }, [urlProds]);

    // scrolls

    const scrollToLocation = () => {
        const { hash } = window.location;
        if (hash !== '') {
        let retries = 0;
        const id = hash.replace('#', '');
        const scroll = () => {
            retries += 0;
            if (retries > 50) return;
            const element = document.getElementById(id);
            if (element) {
            setTimeout(() => element.scrollIntoView(), 0);
            } else {
            setTimeout(scroll, 100);
            }
        };
        scroll();
        }
    } 

    const [searchMoadl, setSearchMoadl] = useState(false);
    const [contr, setContr] = useState(false);
    const [about, setAbout] = useState(false);
    const [best, setBest] = useState(false);
    const [prods, setProds] = useState(false);
    const [categs, setCategs] = useState(false);

    function getAbout(){
        setAbout(true)
        setBest(false)
        setCategs(false)
        setProds(false)
        setSearchMoadl(false)
    }
    function getBest(){
        setBest(true)
        setAbout(false)
        setCategs(false)
        setProds(false)
        setSearchMoadl(false)
    }
    function getProds(){
        setBest(false)
        setAbout(false)
        setCategs(false)
        setProds(true)
        setSearchMoadl(false)
    }
    function getCategs(){
        setCategs(true)
        setBest(false)
        setAbout(false)
        setProds(false)
        setSearchMoadl(false)
    }
    function allFalse(){
        setCategs(false)
        setBest(false)
        setAbout(false)
        setProds(false)
        setSearchMoadl(false)
    }


    function deletSearch(item){
        setSearchMoadl(false)
        showModal(true, item)
    }

    function categsProd(item){
        chageIndex(item)
        setCategs(false)
        setSearchMoadl(false)
    }

    function prodsGet(item){
        setProds(false)
        showModal(true, item)
    }

    // audio

    const audio = useRef(null)           
    const [volume, setVolume] = useState(false)
    
    function playVolume(){
        setVolume(true)
        audio.current.play()
    }
    function endVolume(){
        setVolume(false)
        audio.current.pause()
    }

    
    const [cookie, setCookie] = useState(true)
    
    function showCookie(item){
        playVolume()
        setCookie(item)
        sessionStorage.setItem("cookie", item)
        console.log("OK")
    }
    
    useEffect(() => {        
        setTimeout(() => {
            setCookie(sessionStorage.getItem("cookie") ? JSON.parse(sessionStorage.getItem("cookie")) : false)
        }, 3000);
    }, [])

    return (
        <>
            <div className={`cookie ${cookie && "d-none"}`}>
                <p>{t("cookie")}</p>
                <a href='cookie.html' target="_blank" className='' onClick={() => showCookie(true)}>{t("cust")}</a>
                <button className="btn accept" onClick={() => showCookie(true)}>{t("accept")}</button>
            </div> 
            <div id={`nav`} className={`Navbar`}>
                <nav className="navbar navbar-expand justify-content-between align-items-center">
                    <audio autoPlay src={Audio} type="audio/mpeg" ref={audio}></audio>
                    <div className="navbar-bran portfolio">
                        <a className="nav-link text-dark " href="/#" onPointerEnter={() => allFalse(true)}>
                            <img src={Logo} className="logo" />
                        </a>
                    </div>                    
                    <ul className={`navbar-nav ${show && "show"}`}>                           
                        <li className='nav-item position-relativ' onPointerEnter={() => getAbout(true)}>
                            <Link to="/about" className='nav-link nav--link'>{t("nav1")}</Link>
                            <ul className={`modal-about ${!about && "d-none"}`}>
                                <Link onClick={() => setAbout(false)} className='lin' to="/about">{t("about1")}</Link>
                                <Link onClick={() => setAbout(false)} className='lin' to="/about">{t("about2")}</Link>
                                <Link onClick={() => setAbout(false)} className='lin' to="/about">{t("about3")}</Link>
                            </ul>
                        </li>
                        <li className='nav-item' onPointerEnter={() => getProds(true)}>
                            <a href="/#best" onClick={() => scrollToLocation()} className="nav-link">{t("nav2")}</a>                            
                            <ul className={`modal-prods ${!prods && "d-none"}`}>
                                {dataProds.slice(0,4).map((data) => (
                                    <div onClick={() => prodsGet(data.id)} className='lin'>{english && data.name_en} {uzbek && data.name_uz} {russian && data.name_ru}</div>
                                ))}                                
                            </ul>
                        </li>
                        <li className='nav-item' onPointerEnter={() => getCategs(true)}>
                            <a href="/#categories" onClick={() => scrollToLocation()} className="nav-link">{t("nav3")}</a>
                            <ul className={`modal-categs ${!categs && "d-none"}`}>
                                {dataProducts.map((data) => (
                                    <Link onClick={() => categsProd(data.id)} className='lin' to="/products">{english && data.name_en} {uzbek && data.name_uz} {russian && data.name_ru}</Link>
                                ))}                                
                            </ul>
                        </li>
                        <li className='nav-item' onPointerEnter={() => allFalse(true)}>
                            <a href="/#tour" onClick={() => scrollToLocation()} className="nav-link">{t("nav4")}</a>
                        </li>
                        <li className='nav-item' onPointerEnter={() => getBest(true)}>
                            <a href="/#blogs" onClick={() => scrollToLocation()} className="nav-link">{t("nav5")}</a>
                            <ul className={`modal-best ${!best && "d-none"}`}>
                                {dataBlogs.map((data) => (
                                    <Link onClick={() => setBest(false)} className='lin' to={`/blog${data.id}`}>{english && data.name_en} {uzbek && data.name_uz} {russian && data.name_ru}</Link>
                                ))}                                
                            </ul>
                        </li>
                        <li className='nav-item res' onPointerEnter={() => allFalse(true)}>                            
                            <VolumeOff className={`volume ${volume && "d-none"}`} onClick={() => playVolume()} />
                            <VolumeUp className={`volume ${!volume && "d-none"}`} onClick={() => endVolume()} />
                        </li>
                        <li className='nav-item res' onPointerEnter={() => allFalse(true)}>
                            <div onClick={() => setSearchMoadl(!searchMoadl)} className="nav-link"><img className='search' src={Img2} /></div>
                        </li>
                        <li className='nav-item heartly' onPointerEnter={() => allFalse(true)}>
                            <Link to={`/saved`} className="dfg">
                                <i className='fa fa-heart-o'><p>{countLike}</p></i>
                            </Link>                           
                        </li>
                        <li className='nav-item res me-2' onPointerEnter={() => allFalse(true)}>
                            <div className="language d-flex align-items-center justify-content-between">
                                <div className={`change-language`}>
                                    <input type="checkbox" id="language" checked={language} onChange={() => setLanguage(!language)} />
                                    <label htmlFor='language'>{langTitle} <i className='fa fa-angle-down ms-1'></i></label>
                                    <ul className={`language-menu ${!language && "d-none"}`}>
                                        <li onClick={() => changeHandle1()}><div className='nav-link'>RU</div></li>
                                        <li onClick={() => changeHandle2()}><div className='nav-link'>ENG</div></li>
                                        <li onClick={() => changeHandle3()}><div className='nav-link'>UZ</div></li>                                                                          
                                    </ul> 
                                </div>                                                         
                            </div> 
                        </li>
                        <li className='nav-item' onPointerEnter={() => allFalse(true)}>
                            <a href='tel:+99898-157-00-89' className='nav-link tel'>
                                +99898 157 00 89
                            </a>
                            <a href='tel:+99898-157-00-89' className='call nav-links res'>
                                <LocalPhone className='icon mx-1' />
                                <div className='d-block mx-1'>
                                    <p className='spans d-block'>+99898 157 00 89</p>
                                </div>
                            </a>
                        </li>
                        <i className="fa fa-times" onClick={() => setShow(false)}></i>
                    </ul>
                    <div className="align-items-center justify-content-between responsive-lang">
                        <div className="language d-flex align-items-center justify-content-between">
                            <VolumeOff className={`volume ${volume && "d-none"}`} onClick={() => playVolume()} />
                            <VolumeUp className={`volume ${!volume && "d-none"}`} onClick={() => endVolume()} />
                            <div onClick={() => setSearchMoadl(!searchMoadl)} className="nav-link"><img className='search' src={Img2} /></div>                             
                            <Link to={`/saved`} className="dfg1">
                                <i className='fa fa-heart-o'><p>{countLike}</p></i>
                            </Link>
                            <div className={`change-language`}>
                                <input type="checkbox" id="language" checked={language} onChange={() => setLanguage(!language)} />
                                <label htmlFor='language'>{langTitle} <i className='fa fa-angle-down ms-1'></i></label>
                                <ul className={`language-menu ${!language && "d-none"}`}>
                                    <li onClick={() => changeHandle1()}><div className='nav-link'>RU</div></li>
                                    <li onClick={() => changeHandle2()}><div className='nav-link'>ENG</div></li>
                                    <li onClick={() => changeHandle3()}><div className='nav-link'>UZ</div></li>                                                                          
                                </ul> 
                            </div>                            
                            <i className={`fa fa-bars`} onClick={() => setShow(true)}></i>
                        </div>                                               
                    </div>
                </nav>
                <div className={`modal-search ${!searchMoadl && "d-none"}`}>
                    <input type="text" id='search' value={inputValue} onChange={(e) => filterData(e)} />
                    <Search className='search-icon' />
                    <label htmlFor='search' className='search-tool'>
                        <ul>
                            {dataProd.map((item) => ( 
                                <li key={item.id}>
                                    {
                                        english &&
                                        <div className="search-link" onClick={() => deletSearch(item.id)}><Launch className='launch' /> {item.name_en}</div>
                                    }
                                    {
                                        russian &&
                                        <div className="search-link" onClick={() => deletSearch(item.id)}><Launch className='launch' /> {item.name_ru}</div>
                                    }
                                    {
                                        uzbek &&
                                        <div className="search-link" onClick={() => deletSearch(item.id)}><Launch className='launch' /> {item.name_uz}</div>
                                    }
                                </li>
                            ))}
                            {dataBlog.map((item) => ( 
                                <li key={item.id}>
                                    {
                                        english &&
                                        <Link to={`/blog${item.id}`} className="search-link nav-link" onClick={() => deletSearch(item.id)}><Launch className='launch' /> {item.name_en}</Link>
                                    }
                                    {
                                        russian &&
                                        <Link to={`/blog${item.id}`} className="search-link nav-link" onClick={() => deletSearch(item.id)}><Launch className='launch' /> {item.name_ru}</Link>
                                    }
                                    {
                                        uzbek &&
                                        <Link to={`/blog${item.id}`} className="search-link nav-link" onClick={() => deletSearch(item.id)}><Launch className='launch' /> {item.name_uz}</Link>
                                    }
                                </li>
                            ))}
                        </ul>
                    </label>
                </div>
                <div className={`contr-modal ${!searchMoadl && "d-none"}`} onClick={() => setSearchMoadl(false)}></div>
                <div className={`contr-modal ${!about && "d-none"}`} onClick={() => setAbout(false)}></div>
                <div className={`contr-modal ${!best && "d-none"}`} onClick={() => setBest(false)}></div>
                <div className={`contr-modal ${!categs && "d-none"}`} onClick={() => setCategs(false)}></div>
                <div className={`contr-modal ${!prods && "d-none"}`} onClick={() => setProds(false)}></div>
            </div>
        </>
    );
};

export default NavbarCopy;