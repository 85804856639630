import './Catalog.scss'
import { Link } from 'react-router-dom'
import arrow from '../../Assets/img/arrow.png'
import { useTranslation } from 'react-i18next'

const Catalog = ({chageIndex}) => {

    
    function loadWindow(item){
        window.location.reload(false)
    }

    const {t} = useTranslation()

    return(
        <div className="catalog">
            <div className="container">
            <h2 className="catalogy__name">{t("bestsale")}</h2>
                 <div className="catalogy__title">
                 <Link onClick={() => chageIndex(2)}  to="/products" className="catalogy__link">{t("catalogue")}  <img src={arrow} alt="" className="catalogy__img" /></Link>
                 </div>

                 <ul className="catalog__list">
                    <li className="catalogy__item">
                        {/* <a href='#' className="catalogy__numbers">01-</a> */}
                        <Link to='/catagory1' className="catalogy__numbers">01-</Link>
                    </li>
                    <li className="catalogy__item">
                        {/* <a  href='#' className="catalogy__numbers">02-</a> */}
                        <Link  to='/catagory2' className="catalogy__numbers">02-</Link>
                    </li>
                    <li className="catalogy__item">
                        {/* <a  href='#' className="catalogy__number">03-</a> */}
                        <Link  to='/catagory3' className="catalogy__number">03-</Link>
                    </li>
                 </ul>
            </div>
        </div>
    )
}
export default Catalog